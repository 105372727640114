import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-unfetch';
import { Flex, Text, Image } from 'rebass';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Colors } from '../theme';

const Menu = ({ pageContext: { restaurantId, logoUrl } }) => {
  const [error, setError] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      fetch(
        `https://mangiami.herokuapp.com/api/restaurants/${restaurantId}/menu`,
      )
        .then(r => {
          if (!r.ok) {
            throw new Error('not ok');
          } else {
            return r.json();
          }
        })
        .then(data => {
          const { menuUrl } = data;
          if (!menuUrl) {
            throw new Error('not ok');
          } else {
            window.location.href = menuUrl;
          }
        })
        .catch(() => setError(true));
    }, 1000);
  }, []);
  if (error) {
    return <Text p={2}>Errore caricamento menù</Text>;
  }
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={4}
      flex={1}
    >
      <Image
        my={3}
        width={100}
        height={100}
        style={{ borderRadius: 50 }}
        src={logoUrl}
      />
      <Text>Caricamento menù</Text>
      <Loader type="ThreeDots" color={Colors.RED} height={50} />
    </Flex>
  );
};

Menu.propTypes = {
  pageContext: PropTypes.shape({
    restaurantId: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default Menu;
